<template>
    <div v-if="isCdf" class="content-wrapper settlement-order-summary">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner && hasErrors"
            dismissable
        />
        <rq-page-section title="Cash to Close" header-size="lg" header-only borderless>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <!-- Loan Section (without Seller only) -->
        <rq-page-section title="Loan Amount" collapsible v-if="isWithOutSeller">
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all />
                    </li>
                </ul>
            </template>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group"  >
                        <label for="txt_loanamount_estimate">Loan Estimate</label>
                        <rqInputNumber
                            id="txt_loanamount_estimate"
                            automation_id="txt_loanamount_estimate"
                            :disabled="readOnly"
                            defaultValue="0"
                            format-type="money"
                            decimals="0"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedCashToClose.loanEstimateLoanAmount"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_loanamount_final">Final</label>
                        <rqInputNumber
                            id="txt_loanamount_final"
                            automation_id="txt_loanamount_final"
                            :disabled="true"
                            defaultValue="0"
                            format-type="money"
                            decimals="2"
                            no-prefix
                            input-group
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedCashToClose.finalLoanAmount"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_loanamount_change">Did this change?</label>
                        <rq-radio-group
                            v-model="selectedCashToClose.isLoanAmountChange"
                            inline
                            disabled
                            :options="[
                                { automation_id: 'radio_no', value: false, text: 'NO'},
                                { automation_id: 'radio_yes', value: true, text: 'YES'}
                            ]"
                        />
                    </div>
                </div>
            <div v-if="selectedCashToClose.isLoanAmountChange" class="row">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_loanamount_override"
                        id="chk_loanamount_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isLoanAmountOverride">Loan Amount Override</b-form-checkbox>
                </div>

                <!-- Total Loan Amount Text Override -->
                <div v-if="selectedCashToClose.isLoanAmountOverride" class="col col-12 form-group">
                    <label for="loanamount_override_text">Loan Amount Text</label>
                    <rq-html-editor
                        id="loanamount_override_text"
                        automationId="loanamount_override_text"
                        placeholder="Loan Amount Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.loanAmountOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Total Closing Costs Section -->
        <rq-page-section title="Total Closing Costs (J)" collapsible>
            <template #header-actions>
                <ul v-if="!isWithOutSeller" class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all />
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group"  >
                    <label for="txt_closingcost_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_closingcost_estimate"
                        automation_id="txt_closingcost_estimate"
                        ref="closingCostEstimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateClosingCostAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_closingCost_final">Final</label>
                    <rqInputNumber
                        id="txt_closingCost_fina"
                        automation_id="txt_closingcost_fina"
                        :disabled="true"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.finalClosingCostAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_closingcost_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isClosingCostChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div v-if="selectedCashToClose.isClosingCostChange" class="row">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_closingcost_override"
                        id="chk_closingcost_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isClosingCostOverride">Closing Cost Override</b-form-checkbox>
                </div>

                <!-- Total Closing Costs Text Override -->
                <div v-if="selectedCashToClose.isClosingCostOverride" class="col col-12">
                    <label for="closingcost_override_text">Closing Cost Text</label>
                    <rq-html-editor
                        id="closingcost_override_text"
                        automationId="closingcost_override_text"
                        placeholder="Closing Cost Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.closingCostOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Closing Costs Paid Before Closing -->
        <rq-page-section title="Closing Costs Paid Before Closing" collapsible>
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_beforeclosing_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_beforeclosing_estimate"
                        automation_id="txt_beforeclosing_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateClosingCostPaidBeforeClosingAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_beforeclosing_final">Final</label>
                    <rqInputNumber
                        id="txt_beforeclosing_final"
                        automation_id="txt_beforeclosing_final"
                        :disabled="true"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.finalClosingCostPaidBeforeClosingAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_beforeclosing_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isClosingCostPaidBeforeClosingChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isClosingCostPaidBeforeClosingChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_beforeclosing_override"
                        id="chk_beforeclosing_override"
                        v-model="selectedCashToClose.isClosingCostPaidBeforeClosingOverride">Closing Costs Paid Before Closing Override</b-form-checkbox>
                </div>

                <!-- Closing Costs Paid Before Closing Override -->
                <div v-if="selectedCashToClose.isClosingCostPaidBeforeClosingOverride" class="col col-12 form-group">
                    <label for="beforeclosing_overrride_text">Paid Before Closing Text</label>
                    <rq-html-editor
                        id="beforeclosing_overrride_text"
                        automationId="beforeclosing_overrride_text"
                        placeholder="Paid Before Closing Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.closingCostPaidBeforeClosingOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Closing Costs Financed -->
        <rq-page-section title="Closing Costs Financed (Paid from your Loan Amount)" collapsible v-if="!isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_finance_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_finance_estimate"
                        automation_id="txt_finance_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateClosingCostFinanceAmount"
                    />
                </div>
                <!-- Final Finance Amount is entered manually by user -->
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_finance_final">Final</label>
                    <rqInputNumber
                        id="txt_finance_final"
                        automation_id="txt_finance_final"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.finalClosingCostFinanceAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_finance_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isClosingCostFinanceChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isClosingCostFinanceChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_finance_override"
                        id="chk_financeAmount_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isClosingCostFinanceOverride">Closing Costs Financed Override</b-form-checkbox>
                </div>

                <!-- Closing Costs Finance Override -->
                <div v-if="selectedCashToClose.isClosingCostFinanceOverride" class="col col-12 form-group">
                    <label for="finance_override_text">Closing Costs Financed Text</label>
                    <rq-html-editor
                        id="finance_override_text"
                        automationId="finance_override_text"
                        placeholder="Closing Costs Financed Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.closingCostFinanceOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Down Payment/Funds From Borrower -->
        <rq-page-section title="Down Payment / Funds from Borrower" collapsible v-if="!isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_downpayment_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_downpayment_estimate"
                        automation_id="txt_downpayment_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateDownPaymentFromBorrowerAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_downpayment_final">Final</label>
                    <rqInputNumber
                        id="txt_downpayment_final"
                        automation_id="txt_downpayment_final"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        @blur="onEditDownPayment"
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.finalDownPaymentFromBorrowerAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_down_payment_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isDownPaymentFromBorrowerChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isDownPaymentFromBorrowerChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_downPaymentFromBorrower_override"
                        id="chk_downPaymentFromBorrower_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isDownPaymentFromBorrowerOverride">Down Payment Override</b-form-checkbox>
                </div>

                <!-- Down Payment Override -->
                <div v-if="selectedCashToClose.isDownPaymentFromBorrowerOverride" class="col col-12 form-group">
                    <label for="downPayment_override_text">Down Payment Text</label>
                    <rq-html-editor
                        id="downPayment_override_text"
                        automationId="downPayment_override_text"
                        placeholder="Down Payment Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.downPaymentFromBorrowerOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Deposit -->
        <rq-page-section title="Deposit" collapsible v-if="!isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_deposit_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_deposit_estimate"
                        automation_id="txt_deposit_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateDepositAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_deposit_final">Final</label>
                    <rqInputNumber
                        id="txt_deposit_final"
                        automation_id="txt_deposit_final"
                        @blur="onEditDeposit"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.finalDepositAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_deposit_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isDepositChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isDepositChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_deposit_override"
                        id="chk_deposit_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isDepositOverride">Deposit Override</b-form-checkbox>
                </div>

                <!-- Deposit Text Override -->
                <div v-if="selectedCashToClose.isDepositOverride" class="col col-12 form-group">
                    <label for="deposit_override_text">Deposit Text</label>
                    <rq-html-editor
                        id="deposit_override_text"
                        automationId="deposit_override_text"
                        placeholder="Deposit Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.depositOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Funds for Borrower -->
        <rq-page-section title="Funds for Borrower" collapsible v-if="!isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_borrowerfund_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_borrowerfund_estimate"
                        automation_id="txt_borrowerfund_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateFundForBorrowerAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_borrowerfund_final">Final</label>
                    <rqInputNumber
                        id="txt_borrowerfund_final"
                        automation_id="txt_borrowerfund_final"
                        :disabled="true"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.finalFundForBorrowerAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_borrowerfund_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isFundForBorrowerChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isFundForBorrowerChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_borrowerfund_override"
                        id="chk_borrowerfund_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isFundForBorrowerOverride">Funds for Borrower Override</b-form-checkbox>
                </div>

                <!-- Funds for Borrower Override -->
                <div v-if="selectedCashToClose.isFundForBorrowerOverride" class="col col-12 form-group">
                    <label for="borrowerfund_override_text">Funds for Borrrower Text</label>
                    <rq-html-editor
                        id="borrowerfund_override_text"
                        automationId="borrowerfund_override_text"
                        placeholder="Funds for Borrower Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.fundForBorrowerOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Seller Credits -->
        <rq-page-section title="Seller Credits" collapsible v-if="!isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_sellercred_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_sellercred_estimate"
                        automation_id="txt_sellercred_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateSellerCreditAmount"
                    />
                </div>

                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                    <rq-action-form-group
                        action-class="overridden"
                        label="Final"
                        labelFor="txt_sellercredit_final"
                        :show-action="selectedCashToClose.isFinalSellerCreditAmountOverridden"
                        @action-click="onRevertFinalSellerCreditOverride"
                        action-automation-id="btn_revert_final_seller_credit"
                        action-label="Revert"
                    >
                        <template #default>
                            <rqInputNumber
                                id="txt_sellercredit_final"
                                automation_id="txt_sellercredit_final"
                                defaultValue="0"
                                format-type="money"
                                decimals="2"
                                no-prefix
                                input-group
                                v-focus-select-all
                                prependIcon="fas fa-dollar-sign"
                                :disabled="readOnly"
                                v-model="selectedCashToClose.finalSellerCreditAmountValue"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                    <label for="txt_sellercredit_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isSellerCreditChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isSellerCreditChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_sellercredit_override"
                        id="chk_sellercredit_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isSellerCreditOverride">Seller Credits Override</b-form-checkbox>
                </div>

                <!-- Seller Credits Text Override -->
                <div v-if="selectedCashToClose.isSellerCreditOverride" class="col col-12 form-group">
                    <label for="sellercredit_override_text">Seller Credits Text</label>
                    <rq-html-editor
                        id="sellercredit_override_text"
                        automationId="sellercredit_override_text"
                        placeholder="Seller Credits Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.sellerCreditOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Adjustments and Other Credits -->
        <rq-page-section title="Adjustments and Other Credits" collapsible v-if="!isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_adjustment_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_adjustment_estimate"
                        automation_id="txt_adjustment_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateAdjustmentAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                    <rq-action-form-group
                        action-class="overridden"
                        label="Final"
                        labelFor="txt_adjustment_final"
                        :show-action="selectedCashToClose.isFinalAdjustmentAmountOverridden"
                        @action-click="onRevertFinalAdjustmentOverride"
                        action-automation-id="btn_revert_final_adjustment"
                        action-label="Revert"
                    >
                        <template #default>
                            <rqInputNumber
                                id="txt_adjustment_final"
                                automation_id="txt_adjustment_final"
                                defaultValue="0"
                                format-type="money"
                                decimals="2"
                                no-prefix
                                input-group
                                v-focus-select-all
                                prependIcon="fas fa-dollar-sign"
                                :disabled="readOnly"
                                v-model="selectedCashToClose.finalAdjustmentAmountValue"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                    <label for="txt_adjustment_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isAdjustmentChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isAdjustmentChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_adjustment_override"
                        id="chk_adjustment_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isAdjustmentOverride">Adjustment Override</b-form-checkbox>
                </div>

                <!-- Adjustment Text Override -->
                <div v-if="selectedCashToClose.isAdjustmentOverride" class="col col-12 form-group">
                    <label for="adjustment_override_text">Adjustment Text</label>
                    <rq-html-editor
                        id="adjustment_override_text"
                        automationId="adjustment_override_text"
                        placeholder="adjustment Text"
                        :autoHideToolbar="true"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.adjustmentOverrideText"
                        :height="200"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Payoff Payments Section (without Seller only) -->
        <rq-page-section title="Total Payoffs and Payments (K)" collapsible v-if="isWithOutSeller">
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group"  >
                    <label for="txt_payoffpayment_estimate">Loan Estimate</label>
                    <rqInputNumber
                        id="txt_payoffpayment_estimate"
                        automation_id="txt_payoffpayment_estimate"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.loanEstimateTotalPayoffPaymentAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_payoffpayment_final">Final</label>
                    <rqInputNumber
                        id="txt_payoffpayment_final"
                        automation_id="txt_payoffpayment_fina"
                        :disabled="true"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.finalTotalPayoffPaymentAmount"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_payoffpayment_change">Did this change?</label>
                    <rq-radio-group
                        v-model="selectedCashToClose.isTotalPayoffPaymentChange"
                        inline
                        disabled
                        :options="[
                            { automation_id: 'radio_no', value: false, text: 'NO'},
                            { automation_id: 'radio_yes', value: true, text: 'YES'}
                        ]"
                    />
                </div>
            </div>
            <div class="row" v-if="selectedCashToClose.isTotalPayoffPaymentChange">
                <div class="col col-12 form-group ps-4">
                    <b-form-checkbox
                        automation_id="chk_payoffpayment_override"
                        id="chk_payoffpayment_override"
                        :disabled="readOnly"
                        v-model="selectedCashToClose.isTotalPayoffPaymentOverride">Payoff Payment Override</b-form-checkbox>
                </div>

                <!-- Total Payoff Payments Text Override -->
                <div v-if="selectedCashToClose.isTotalPayoffPaymentOverride" class="col col-12 form-group">
                    <label for="payoffpayment_override_text">Payoff Payment Text</label>
                    <rq-html-editor
                        id="payoffpayment_override_text"
                        automationId="payoffpayment_override_text"
                        placeholder="Payoff Payment Text"
                        :autoHideToolbar="true"
                        :height="200"
                        v-model="selectedCashToClose.totalPayoffPaymentOverrideText"
                    />
                </div>
            </div>
        </rq-page-section>

        <!-- Cash To Close -->
        <rq-page-section title="Cash to Close" collapsible>
            <div class="row">
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_cashtoclose_estimate">Loan Estimate {{estimateResultText}}</label>
                    <rqInputNumber
                        id="txt_cashtoclose_estimate"
                        automation_id="txt_cashtoclose_estimate"
                        :disabled="true"
                        defaultValue="0"
                        format-type="money"
                        decimals="0"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="loanEstimateTotalResult"
                    />
                </div>
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_cashtoclose_final">Final {{finalResultText}}</label>
                    <rqInputNumber
                        id="txt_cashtoclose_final"
                        automation_id="txt_cashtoclose_final"
                        :disabled="true"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="finalTotalResult"
                    />
                </div>
                <!-- Final Finance Amount is entered manually by user -->
                <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="isWithOutSeller">
                    <label for="txt_finance_final">Closing Costs Financed (Paid from Loan Amount)</label>
                    <rqInputNumber
                        id="txt_finance_final"
                        automation_id="txt_finance_final"
                        :disabled="readOnly"
                        defaultValue="0"
                        format-type="money"
                        decimals="2"
                        no-prefix
                        input-group
                        v-focus-select-all
                        prependIcon="fas fa-dollar-sign"
                        v-model="selectedCashToClose.financedClosingCosts"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
    <div v-else class="content-wrapper">
        <rq-no-data text="Cash To Close only available for CDF" />
    </div>
</template>

<script>
    /* eslint-disable */
    /* Disabling eslint errors here for now (vue/no-side-effects-in-computed-properties), but they will need to be addressed soon and this component refactored to more reliably derive calculations */

    import { GlobalEventManager } from '@/app.events';
    import { CashToCloseSectionDataModel, SETTLEMENT_TYPE } from '@settlement/models';
    import { ORDER_ACTIONS } from "@/store/actions";
    import RqHtmlEditor from '@/shared/components/rq/editors/RqHtmlEditor';
    import BaseSettlementMixin from "../../BaseSettlementMixin";
    import { DateTime } from "luxon";

    export default {

        name: 'CashToClose',
        mixins: [BaseSettlementMixin],
        components: {
            RqHtmlEditor,
        },
        data(){
            return{
                selectedCashToClose: {},
                orderCashToClose:[],
                originalCashToClose:[]
            }
        },
        props: {
            finalTotal: {
                type: Number
            }
        },
        computed:{
            currentYear() {
                return DateTime.now().year;
            },
            loanEstimateTotalResult () {
                const self = this;

                /* vue/no-side-effects-in-computed-properties */
                self.selectedCashToClose.loanEstimateTotalAmountWithoutSeller = self.selectedCashToClose.loanEstimateLoanAmount +
                    self.selectedCashToClose.loanEstimateClosingCostAmount +
                    self.selectedCashToClose.loanEstimateClosingCostPaidBeforeClosingAmount +
                    self.selectedCashToClose.loanEstimateTotalPayoffPaymentAmount;

                /* vue/no-side-effects-in-computed-properties */
                self.selectedCashToClose.loanEstimateTotalAmount = self.selectedCashToClose.loanEstimateClosingCostAmount +
                    self.selectedCashToClose.loanEstimateClosingCostPaidBeforeClosingAmount +
                    self.selectedCashToClose.loanEstimateClosingCostFinanceAmount +
                    self.selectedCashToClose.loanEstimateDownPaymentFromBorrowerAmount +
                    self.selectedCashToClose.loanEstimateDepositAmount +
                    self.selectedCashToClose.loanEstimateFundForBorrowerAmount +
                    self.selectedCashToClose.loanEstimateSellerCreditAmount +
                    self.selectedCashToClose.loanEstimateAdjustmentAmount;

                if (self.isWithOutSeller) {
                    return self.selectedCashToClose.loanEstimateTotalAmountWithoutSeller;
                }
                else {
                    return self.selectedCashToClose.loanEstimateTotalAmount;
                }
            },
            finalTotalResult () {
                const self = this;

                /* vue/no-side-effects-in-computed-properties */
                self.selectedCashToClose.finalTotalAmountWithoutSeller = self.selectedCashToClose.finalLoanAmount +
                    self.selectedCashToClose.finalClosingCostAmount +
                    self.selectedCashToClose.finalClosingCostPaidBeforeClosingAmount +
                    self.selectedCashToClose.finalTotalPayoffPaymentAmount;

                /* vue/no-side-effects-in-computed-properties */
                self.selectedCashToClose.finalTotalAmount = self.selectedCashToClose.finalClosingCostAmount +
                    self.selectedCashToClose.finalClosingCostPaidBeforeClosingAmount +
                    self.selectedCashToClose.finalClosingCostFinanceAmount +
                    self.selectedCashToClose.finalDownPaymentFromBorrowerAmount +
                    self.selectedCashToClose.finalDepositAmount +
                    self.selectedCashToClose.finalFundForBorrowerAmount +
                    (self.selectedCashToClose.finalSellerCreditOverrideAmount ?? self.selectedCashToClose.finalSellerCreditAmount) +
                    (self.selectedCashToClose.finalAdjustmentOverrideAmount ?? self.selectedCashToClose.finalAdjustmentAmount);

                if (self.isWithOutSeller) {
                    return self.selectedCashToClose.finalTotalAmountWithoutSeller;
                }
                else {
                    return self.selectedCashToClose.finalTotalAmount;
                }
            },
            estimateResultText() {
                if (this.loanEstimateTotalResult < 0)
                    return "Cash To Borrower";
                else
                    return "Cash From Borrower";
            },
            finalResultText() {
                if (this.finalTotalResult < 0)
                    return "Cash To Borrower"
                else
                    return "Cash From Borrower"
            }
        },
        watch: {
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0) return;

                self.save().then(()=>{
                    self.fetchData();
                });
            },
            "selectedCashToClose.loanEstimateLoanAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue === oldValue) return;
                    if (newValue === self.selectedCashToClose.finalLoanAmount)
                        self.selectedCashToClose.isLoanAmountChange = false;
                    else
                        self.selectedCashToClose.isLoanAmountChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue; // balance out previous value
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue; // adjust with new value
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateClosingCostAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalClosingCostAmount)
                        self.selectedCashToClose.isClosingCostChange = false;
                    else if (newValue !=  self.selectedCashToClose.finalClosingCostAmount)
                        self.selectedCashToClose.isClosingCostChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateClosingCostPaidBeforeClosingAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalClosingCostPaidBeforeClosingAmount)
                        self.selectedCashToClose.isClosingCostPaidBeforeClosingChange = false;
                    else if (newValue != self.selectedCashToClose.finalClosingCostPaidBeforeClosingAmount)
                        self.selectedCashToClose.isClosingCostPaidBeforeClosingChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateClosingCostFinanceAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalClosingCostFinanceAmount)
                        self.selectedCashToClose.isClosingCostFinanceChange = false;
                    else if (newValue != self.selectedCashToClose.finalClosingCostFinanceAmount)
                        self.selectedCashToClose.isClosingCostFinanceChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.finalClosingCostFinanceAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.loanEstimateClosingCostFinanceAmount)
                        self.selectedCashToClose.isClosingCostFinanceChange = false;
                    else if (newValue != self.selectedCashToClose.loanEstimateClosingCostFinanceAmount)
                        self.selectedCashToClose.isClosingCostFinanceChange = true;

                    self.selectedCashToClose.finalTotalAmount -= oldValue;
                    self.selectedCashToClose.finalTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateDownPaymentFromBorrowerAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalDownPaymentFromBorrowerAmount)
                        self.selectedCashToClose.isDownPaymentFromBorrowerChange = false;
                    else if (newValue != self.selectedCashToClose.finalDownPaymentFromBorrowerAmount)
                        self.selectedCashToClose.isDownPaymentFromBorrowerChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateDepositAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalDepositAmount)
                        self.selectedCashToClose.isDepositChange = false;
                    else if (newValue != self.selectedCashToClose.finalDepositAmount)
                        self.selectedCashToClose.isDepositChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateFundForBorrowerAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalFundForBorrowerAmount)
                        self.selectedCashToClose.isFundForBorrowerChange = false;
                    else if (newValue != self.selectedCashToClose.finalFundForBorrowerAmount)
                        self.selectedCashToClose.isFundForBorrowerChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateSellerCreditAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalSellerCreditAmount)
                        self.selectedCashToClose.isSellerCreditChange = false;
                    else if (newValue != self.selectedCashToClose.finalSellerCreditAmount)
                        self.selectedCashToClose.isSellerCreditChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateAdjustmentAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue == oldValue) return;
                    if (newValue == self.selectedCashToClose.finalAdjustmentAmount)
                        self.selectedCashToClose.isAdjustmentChange = false;
                    else if (newValue != self.selectedCashToClose.finalAdjustmentAmount)
                        self.selectedCashToClose.isAdjustmentChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue;
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue;
                },
                immediate: true,
            },
            "selectedCashToClose.loanEstimateTotalPayoffPaymentAmount": {
                handler: function (newValue, oldValue) {
                    const self = this;
                    if (newValue === oldValue) return;
                    if (newValue === self.selectedCashToClose.finalTotalPayoffPaymentAmount)
                        self.selectedCashToClose.isTotalPayoffPaymentChange = false;
                    else
                        self.selectedCashToClose.isTotalPayoffPaymentChange = true;

                    self.selectedCashToClose.loanEstimateTotalAmount -= oldValue; // balance out previous value
                    self.selectedCashToClose.loanEstimateTotalAmount += newValue; // adjust for new value
                },
                immediate: true,
            },
        },
        mounted(){
            this.resetFocus();
        },
        created(){
            this.baseInit();
            this.fetchData();
        },
        beforeUnmount () {
            GlobalEventManager.unregister(this);
        },
        methods:{
            onRevertFinalAdjustmentOverride() {
                if(this.selectedCashToClose)
                    this.selectedCashToClose.finalAdjustmentOverrideAmount = null;
            },
            onRevertFinalSellerCreditOverride() {
                if(this.selectedCashToClose)
                    this.selectedCashToClose.finalSellerCreditOverrideAmount = null;
            },
            onEditDownPayment(e) {
                const self = this;
                let loan = self.selectedLoan(self.selectedLoanId);
                let newDownPayment = e.value;
                let origDownPayment = self.selectedCashToClose.finalDownPaymentFromBorrowerAmount;

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.selectedCashToClose.finalDownPaymentFromBorrowerAmount = origDownPayment;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newDownPayment !== origDownPayment) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Down Payment for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            onEditDeposit(e) {
                const self = this;
                let loan = self.selectedLoan(self.selectedLoanId);
                let newDeposit = e.value;
                let origDeposit = self.selectedCashToClose.finalDepositAmount;

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.selectedCashToClose.finalDepositAmount = origDeposit;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newDeposit !== origDeposit) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Down Payment for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            selectedLoan(loanId){
                const self = this;
                let selected = _.find(self.loans, l => l.loanID === loanId);
                return selected;
            },
            onSave(e){
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(userInitiated);
            },

            onCancel(e){
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            save(toastIfNoChanges=false){
                const self = this;
                let original = _.find(self.originalCashToClose, s => s.cashToCloseSectionDataID === self.selectedCashToClose.cashToCloseSectionDataID);
                if(_.isNil(original)) return Promise.resolve(false);

                if(!self.hasChanges()){
                    if(toastIfNoChanges) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let promise = self.$api.CashToCloseApi.update(self.selectedCashToClose)
                self.$rqBusy.wait(promise)
                    .then(()=>{
                        //reset original data
                        self.originalCashToClose = _.cloneDeep(self.cashToCloses);
                        return self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {orderId: self.orderId, refresh:true});
                    })
                    .then(() => {
                        self.$toast.success("Cash To Close Saved.");
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    })
                    .catch(err =>{
                        GlobalEventManager.saveCompleted({success: false});
                        self.$toast.error("An issue occurred while saving Cash to Close.");
                        console.error(err);
                        return false;
                    });
                return promise;
            },

            hasChanges() {
                const self = this;
                let original = _.find(self.originalCashToClose, s => s.cashToCloseSectionDataID === self.selectedCashToClose.cashToCloseSectionDataID);
                if(_.isNil(original)) return false;
                let changes = self.getAuditChanges(original, self.selectedCashToClose);
                return changes.length > 0;
            },

            fetchData(){
                const self = this;
                let promise = self.$api.CashToCloseApi.getByOrderId(self.orderId);
                self.$rqBusy.wait(promise).then(result => {
                    self.cashToCloses = _.map(result, i => new CashToCloseSectionDataModel(i));
                    self.originalCashToClose = _.cloneDeep(self.cashToCloses);
                    self.selectedCashToClose = _.find(self.cashToCloses, s => s.loanID === self.selectedLoanId);
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            },

            resetFocus(){
                if (this.loans.length === 1)
                    this.$refs.closingCostEstimate.focus();
            }
        }
    }
</script>