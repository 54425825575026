const partyTypes = {
    Borrower: 3, Buyer: 2, Lender: 0, Seller: 1
};
export class PartyTypes {
    static get Borrower () {return partyTypes.Borrower;}
    static get Buyer () {return partyTypes.Buyer;}
    static get Lender () {return partyTypes.Lender;}
    static get Seller () {return partyTypes.Seller;}

    static get lookupItems () {
        return _.map(partyTypes, c => { return { id: c, name: PartyTypes.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(partyTypes, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const letterTransactionTypes = {
    Cash: "Cash Transaction Letter", Single: "Single Transaction Letter"
};
export class LetterTransactionTypes {
    static get Cash () {return letterTransactionTypes.Cash;}
    static get Single () {return letterTransactionTypes.Single;}

    static get lookupItems () {
        return _.map(letterTransactionTypes, c => { return { id: c, name: LetterTransactionTypes.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(letterTransactionTypes, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}
