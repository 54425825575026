export class CplAddress
{
    constructor (options) {
        options = options || {};
        this.line1 = options.line1 || null;
        this.line2 = options.line2 || null;
        this.city = options.city || null;
        this.stateCode = options.stateCode || null;
        this.zipCode = options.zipCode || null;
    }
}

export class CplOrder
{
    constructor (options) {
        options = options || {};
        this.agentContact = options.agentContact || {};
        this.deliverToAgentViaFax = _.parseBool(options.deliverToAgentViaFax, false);
        this.deliverToAgentViaEmail = _.parseBool(options.deliverToAgentViaEmail, false);
        this.branchOfficeId = options.branchOfficeId || null;
        this.includeBranchOfficeOnThisLetter = _.parseBool(options.includeBranchOfficeOnThisLetter, true);
        this.includeAllOfTheAgencyBranchOfficesOnThisLetter = _.parseBool(options.includeAllOfTheAgencyBranchOfficesOnThisLetter, false);
        this.closingAttorneyId = options.closingAttorneyId || null;
        this.closingAttorneyFaxNumber = options.closingAttorneyFaxNumber || null;
        this.deliverToClosingAttorneyViaFax = _.parseBool(options.deliverToClosingAttorneyViaFax, false);
        this.deliverToClosingAttorneyViaEmail = _.parseBool(options.deliverToClosingAttorneyViaEmail, true);
        this.deliverToAddresseeViaFax = _.parseBool(options.deliverToAddresseeViaFax, false);
        this.letterType = options.letterType || null;
        this.letterTransactionType = options.letterTransactionType || null;
        this.pertainsToLine1 = options.pertainsToLine1 || null;
        this.pertainsToLine2 = options.pertainsToLine2 || null;
        this.property = options.property || {};
        this.transactionFundsAmount = _.parseNumber(options.transactionFundsAmount, 0);
        this.loanNumber = options.loanNumber || null;
        this.transactionParties = options.transactionParties || [];
    }
}

export class CplTransactionParty
{
    constructor (options) {
        options = options || {};
        this.name = options.name || null;
        this.nameContinued = options.nameContinued || null;
        this.contactName = options.contactName || null;
        this.telephoneNumber = options.telephoneNumber || null;
        this.faxNumber = options.faxNumber || null;
        this.partyType = _.parseNumber(options.partyType, 5);
        this.address = options.address || { line1: null, line2: null, city: null, stateCode: null, zipCode: null };
        this.isAddressee = true;
        this.isCovered = true;
    }
}