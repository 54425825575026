<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="automation_id"
            :data-source="gridDataSource"
            :config="gridConfig"
            @selectionChanged="onSelectionChanged"
            @rowDoubleClick="onRowDoubleClick"
            hide-default-actions
            hide-settings
            rq-filters
            fixed-header
            integrated-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "FileContactSelection",

        props: {
            automation_id: { type: String, default: "dg_select_file_contact" },
            ordersID: { type: Number, default: 0 },
            visibleColumns: { type: Array, default: () => [] },
            filterRolesForSigning: { type: Boolean, default: false }
        },

        data () {
            return {
                contacts: [],
                selectedContact: {}
            };
        },

        computed: {
            ...mapState({
                orderContacts: state => state.orders.contacts.contacts,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance () { return _.get(this, "$refs.dataGrid.gridInstance", null); },
        },

        watch: {
            visibleColumns(newValue, oldValue) {
                let diffs = this.diff(newValue, oldValue);
                if(diffs.length === 0) return;
                let gridColumns = this.getGridColumns(newValue)
                this.gridInstance.option("columns", gridColumns);
            }
        },

        created () {
            this.init();
            this.initGridConfig();
            this.$emit("disable-ok");
        },

        methods: {
            fetchData() {
                const self = this;
                let apiPromise = self.$api.OrdersApi.getOrderContacts(self.ordersID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.contacts = _.filter(_.uniq(result), c => !self.filterRolesForSigning || c.buyerSellerID !== null || (c.contactID !== null && self.isRoleTypeAllowedForSigning(c)));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading File Contacts.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            init () {
                const self = this;
                if (self.ordersID == 0) {
                    self.contacts = _.filter(_.clone(self.orderContacts), c =>  !self.filterRolesForSigning || c.buyerSellerID !== null || (c.contactID !== null && self.isRoleTypeAllowedForSigning(c.role)));
                } else {
                    self.fetchData();
                }
            },

            initGridConfig() {
                const self = this;

                self.defaultColumns = [
                    { dataField: "rolesID", visible: false, allowSearch: false, showInColumnChooser: false },
                    {
                        dataField: "contactName",
                        dataType: "string",
                        calculateCellValue: function(rowData) {
                            return (rowData.contactID > 0 || rowData.buyerSellerID > 0) ? rowData.name : null;
                        }
                    },
                    { dataField: "role", dataType: "string" },
                    { dataField: "name", dataType: "string" },
                    { dataField: "cell", dataType: "string" },
                    { dataField: "home", dataType: "string" },
                    { dataField: "email", dataType: "string" },
                ];

                let columns = this.getGridColumns();
                self.gridConfig = {
                    columns,
                    columnMinWidth: 80,
                    cacheEnabled: false,
                    remoteOperations: { paging: false, sorting: false, filtering: false },
                    selection: { mode: "single" }
                };
                self.gridDataSource = {
                    key: "rolesID",
                    load (loadOptions) {
                        return Promise.resolve(self.contacts);
                    },
                };
            },

            onRowDoubleClick (e) {
                if (_.isEmpty(e.data)) return;
                this.selectedContact = e.data;
                this.$emit("ok");
            },

            onSelectionChanged (e) {
                this.selectedContact = e.selectedRowsData[0];
                if (!_.isEmpty(this.selectedContact)) {
                    this.$emit("enable-ok");
                } else {
                    this.$emit("disable-ok");
                }
            },

            getGridColumns(visibleFields) {
                let gridColumns = this.defaultColumns.slice();
                let visibleColumns = visibleFields || this.visibleColumns;
                if(_.isEmpty(visibleColumns)) return gridColumns;
                _.forEach(gridColumns, c => {
                    c.visible = _.includes(visibleColumns, c.dataField);
                });
                return gridColumns;
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.refresh();
            },

            updateDimensions() {
                _.invoke(this, "$refs.dataGrid.gridInstance.updateDimensions");
            },
            isRoleTypeAllowedForSigning(contact) {
                return !_.isNil(contact.signingRoleTypeID) && contact.signingRoleTypeID !== 0;
            }
        }
    };
</script>