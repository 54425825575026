<template>
    <div class="content-wrapper" id="cpl-grid">
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Closing Protection Letters"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            :rq-editable="!readOnly"
            @cancel="onCancel"
            @close="onClose"
            @edit="onEdit"
            @original-cpl="onPreview($event, 'original')"
            @closed-cpl="onPreview($event, 'closed')"
            integrated-search
            rq-filters
            >
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item">
                        <div class="dropdown rq-section-dropdown">
                            <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                            <ul class="dropdown-menu">
                                <li v-rq-tooltip.hover.right="canAddBorrower ? '' : 'No Borrowers, or missing address information'">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_borrower" :disabled="!canAddBorrower" @click="onAdd(3)">Borrower</button>
                                </li>
                                <li v-rq-tooltip.hover.right="canAddBuyer ? '' : 'No Buyers, or missing address information'">
                                    <button type="button" class="dropdown-item" automation_id="btn_add_buyer" :disabled="!canAddBuyer" @click="onAdd(2)">Buyer</button>
                                </li>
                                <li v-rq-tooltip.hover.right="canAddLender ? '' : 'No Lenders, or missing address information'">
                                    <button type="button" class="dropdown-item" automation_id="btn_lender" :disabled="!canAddLender" @click="onAdd(0)">Lender</button>
                                </li>
                                <li v-rq-tooltip.hover.right="canAddSeller ? '' : 'No Sellers, or missing address information'">
                                    <button type="button" class="dropdown-item" automation_id="btn_seller" :disabled="!canAddSeller" @click="onAdd(1)">Seller</button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
import { mapState } from "vuex";
import AddEditCmClosingProtectionLetter from "./AddEditCmClosingProtectionLetter";
import PdfViewer from "@/shared/components/rq/dialogs/EditPdfDialog";
import { FileScanDocumentAction }  from "@document-mgmt/enums";
import { PartyTypes } from "./enums";

export default {
    name: "ClosingProtectionLetterList",
    data: function () {
        return {
            gridDataSource: [],
            gridConfig: {},
            config: {},
            items: [],
            contacts: []
        }
    },
    computed: {
        ...mapState({
            orderId: state => state.orders.orderId,
            readOnly: state => state.isPageReadOnly,
            user: state => state.authentication.session.user,
            properties: state => state.properties.properties,
            loans: state => state.orders.loans
        }),
        canAddBorrower() { return _.some(this.contacts, { partyType: PartyTypes.Buyer, hasFullAddress: true }); },
        canAddBuyer() { return _.some(this.contacts, { partyType: PartyTypes.Buyer, hasFullAddress: true }); },
        canAddLender() { return _.some(this.contacts, { partyType: PartyTypes.Lender, hasFullAddress: true }); },
        canAddSeller() { return _.some(this.contacts, { partyType: PartyTypes.Seller, hasFullAddress: true }); },
        gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
        itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
        itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
        itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
        selectionActions() {
            return [
                {
                    name: "edit",
                    text: "Edit",
                    eventName: "edit",
                    requireSelection: true,
                    allowMultiSelection: false,
                    tooltip: `Edit CPL`,
                    disabled: function(e) {
                        if (!_.isEqual(e.data.orderStatus, "Active")) return `Cannot edit order with this status`;
                        return false;
                    },
                },
                {
                    name: "close",
                    text: "Close",
                    eventName: "close",
                    requireSelection: true,
                    allowMultiSelection: true,
                    tooltip: `Close CPL`,
                    disabled: function(e) {
                        if (!_.every(e.data, {orderStatus: "Active"})) return `One or more orders is not Active`;
                        return false;
                    },
                },
                {
                    name: "preview",
                    text: "View CPL",
                    eventName: "preview",
                    requireSelection: true,
                    allowMultiSelection: false,
                    children: [
                        { name: "original-cpl", text: "Original CPL", eventName: "original-cpl", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data.fileScanDocumentID, 0) == 0;} },
                        { name: "closed-cpl", text: "Closed CPL", eventName: "closed-cpl", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data.closedFileScanDocumentID, 0) == 0; } },
                        ],
                },
                {
                    name: "cancel",
                    text: "Cancel",
                    eventName: "cancel",
                    requireSelection: true,
                    allowMultiSelection: true,
                    tooltip: `Cancel CPL`,
                    disabled: function(e) {
                        if (!_.every(e.data, {orderStatus: "Active"})) return `One or more orders is not Active`;
                        return false;
                    },
                }
            ];
        },
    },
    created() {
        this.initGridConfig();
        this.fetchData();
    },
    methods: {
        cancel(ids){
            const self = this;
            let apiPromise = self.$api.CmClosingProtectionLettersApi.cancelClosingProtectionLetter(self.orderId, ids);
            return self.$rqBusy.wait(apiPromise);
        },
        close(ids){
            const self = this;
            let apiPromise = self.$api.CmClosingProtectionLettersApi.closeClosingProtectionLetter(self.orderId, ids);
            return self.$rqBusy.wait(apiPromise);
        },
        elementName(prefix = "", suffix = "") {
            return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
        },
        fetchData() {
            const self = this;
            let apiPromise = self.$api.CmClosingProtectionLettersApi.getClosingProtectionLetterData(self.orderId);
            self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.setData(result);
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error loading Closing Market CPL Info.` });
                })
                .finally(() => {
                    self.refresh();
                });
        },
        initGridConfig() {
            const self = this;
            const partyTypeLookups = PartyTypes.lookupItems;
            const orderStatusLookups = [ {name: "Active"}, {name: "Cancelled"}, {name: "Closed"}];
            const letterTransactionLookups = [ {name: "Single"}, {name: "Cash"}];
            self.gridConfig = {
                columns: [
                    {
                        dataField: "referenceNumber",
                        dataType: "string",
                        caption: "Reference No.",
                    },
                    {
                        dataField: "order.transactionParties[0].name",
                        dataType: "string",
                        caption: "Addressee Name",
                    },
                    {
                        dataField: "partyType",
                        dataType: "string",
                        caption: "Party Type",
                        lookup: {
                            dataSource: partyTypeLookups,
                            displayExpr: "name",
                            valueExpr: "id",
                        },
                        rqFilter: {
                            valueExpr: "id",
                            displayExpr: "name",
                            dataSource: partyTypeLookups,
                            listOperator: "or",
                            valueOperator: "contains",
                        },
                        visible: false,
                        showInColumnChooser: true
                    },
                    {
                        dataField: "order.letterTransactionType",
                        dataType: "string",
                        caption: "Transaction Type",
                        rqFilter: {
                            valueExpr: "name",
                            displayExpr: "name",
                            dataSource: letterTransactionLookups,
                            listOperator: "or",
                            valueOperator: "contains",
                        },
                    },
                    {
                        dataField: "order.letterType",
                        dataType: "string",
                        caption: "Letter Type",
                    },
                    {
                        dataField: "order.transactionFundsAmount",
                        dataType: "number",
                        caption: "Funds Amount",
                        format: {
                            type: "currency",
                            precision: 2
                        },
                    },
                    {
                        dataField: "orderStatus",
                        dataType: "string",
                        caption: "Status",
                        rqFilter: {
                            valueExpr: "name",
                            displayExpr: "name",
                            dataSource: orderStatusLookups,
                            listOperator: "or",
                            valueOperator: "contains",
                        },
                    },
                    {
                        dataField: "modifiedByName",
                        dataType: "string",
                        caption: "Modified By",
                    },
                    {
                        dataField: "modifiedDate",
                        dataType: "datetime",
                        caption: "Modified Date",
                    },
                ],
            };
            self.gridDataSource = {
                key: "cmClosingProtectionLetterID",
                load(loadOptions) {
                    return Promise.resolve(self.items);
                },
            };
        },
        onAdd(cplType) {
            this.showCplDialog(cplType);
        },
        onCancel(e) {
            const self = this;
            if(!e || !e.data) return;
            const ids = _.map(e.data, "cmClosingProtectionLetterID");
            const noun = _.size(ids) > 1 ? self.itemTypeName : self.itemTypeNamePlural;
            self.cancel(ids)
                .then(result => {
                    self.setData(result);
                    self.$toast.success({ message: `Successfully Cancelled ${noun}.` });
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error Closing CPL.` });
                })
                .finally(() => {
                    self.refresh();
                });
        },
        onClose(e) {
            const self = this;
            if(!e || !e.data) return;
            const ids = _.map(e.data, "cmClosingProtectionLetterID");
            const noun = _.size(ids) > 1 ? self.itemTypeName : self.itemTypeNamePlural;
            self.close(ids)
                .then(result => {
                        self.setData(result);
                        self.$toast.success({ message: `Successfully Closed ${noun}.` });
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error Closing CPL.` });
                })
                .finally(() => {
                    self.refresh();
                });
        },
        onEdit(e) {
            const self = this;
            if(!e || !e.data) return;
            let onOk = (e) => {
                return e.component.update()
                    .then(() => {
                        self.fetchData();
                        return true;
                    })
                    .catch(error => {
                        e.component.errorMessage = error.errorMessage;
                        return false;
                    });
            };
            let onClose = (e) => {
                self.refresh();
                return true;
            };
            const cplType = e.data.partyType;
            const title = `Edit ${PartyTypes.displayValue(cplType)} Closing Protection Letter`;
            self.$dialog.open({
                title: title,
                width: "85%",
                height: "85%",
                resizable: false,
                props: {
                    cplType,
                    existingCpls: [],
                    contacts: self.contacts,
                    lookupData: self.config.lookupData,
                    referenceNumber: e.data.referenceNumber,
                    cmClosingProtectionLetterID: e.data.cmClosingProtectionLetterID,
                    cplOrder: e.data.order
                },
                component: AddEditCmClosingProtectionLetter,
                onOk: onOk,
                onClose: onClose
            });
        },
        onPreview(e, type) {
            const self = this;
            if(!e || !e.data) return;
            const id = _.isEqual(type, "original") ? e.data.fileScanDocumentID : e.data.closedFileScanDocumentID;
            const cplType = e.data.partyType;
            self.preview(id)
                .then(dto => {
                    self.showDocPreviewDialog(cplType, dto);
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error({ message: `Error Previewing CPL.` });
                });
        },
        preview(id){
            const self = this;
            let apiPromise = self.$api.FileScanApi.getFileScanDocument(id, FileScanDocumentAction.Preview);
            return self.$rqBusy.wait(apiPromise);
        },
        refresh() {
            if (_.isNull(this.gridInstance)) return;
            this.gridInstance.clearSelection();
            this.gridInstance.refresh();
        },
        setData(data) {
            const self = this;
            self.items = data.items;
            self.config = data.config;
            self.contacts = data.contacts;
        },
        showCplDialog(cplType) {
            const self = this;
            let onOk = (e) => {
                return e.component.save()
                    .then(() => {
                        self.fetchData();
                        return true;
                    })
                    .catch(error => {
                        e.component.errorMessage = error.errorMessage;
                        return false;
                    });
            };
            const title = `Add ${PartyTypes.displayValue(cplType)} Closing Protection Letter`;
            self.$dialog.open({
                title: title,
                width: "85%",
                height: "85%",
                resizable: false,
                props: {
                    cplType,
                    existingCpls: [],
                    contacts: self.contacts,
                    lookupData: self.config.lookupData
                },
                component: AddEditCmClosingProtectionLetter,
                onOk: onOk
            });
        },
        showDocPreviewDialog(cplType, dto) {
            const self = this;
            let onOk = (e) => {
                self.refresh();
                return true;
            };
            const title = `Preview ${PartyTypes.displayValue(cplType)} Closing Protection Letter`;
            self.$dialog.open({
                title: title,
                height: "85%",
                width: "85%",
                resizable: true,
                component: PdfViewer,
                props: {
                    content: dto.content,
                    readOnly: true
                },
                okTitle: "Close",
                okOnly: true,
                onOk: onOk,
            });
        },
    }
}
</script>