<template>
    <div class="content-wrapper rq-container">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="showBanner"
            @dismiss="errorMessage=''"
            dismissable
            class="mt-2 mb-2"
        />
        <rq-section-card :title="title">
            <div class="row">
                <div :class="{ 'col col-6 col-xl-6 form-group form-required': true, 'has-error': v$.item.property.line1.$error }">
                    <label for="drp_property">Property</label>
                    <dx-select-box
                        automation_id="drp_property"
                        value-expr="id"
                        display-expr="name"
                        :data-source="propertyList"
                        @valueChanged="onChangeProperty"
                        v-model="propertyID"
                        :search-enabled="false"
                        />
                    <rq-validation-feedback>Property is required</rq-validation-feedback>
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="drp_transactionType">Transaction Type</label>
                    <dx-select-box
                        automation_id="drp_transactionType"
                        value-expr="id"
                        display-expr="name"
                        :data-source="transactionTypes"
                        v-model="item.letterTransactionType"
                        :search-enabled="false"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="drp_letterType">Letter Type</label>
                    <dx-select-box
                        automation_id="drp_letterType"
                        value-expr="letterTypeName"
                        display-expr="letterTypeName"
                        :data-source="letterTypes"
                        v-model="item.letterType"
                        :search-enabled="false"
                    />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-4 col-xl-4 form-group form-required': true, 'has-error': v$.item.pertainsToLine1.$error }">
                    <label for="txt_pertainsToLine1">Pertains To</label>
                    <input automation_id="txt_pertainsToLine1"
                        class="form-control"
                        type="text"
                        v-model="v$.item.pertainsToLine1.$model"
                    />
                    <rq-validation-feedback>Pertains To is required</rq-validation-feedback>
                </div>
                <div :class="{ 'col col-4 col-xl-4 form-group': true }">
                    <label for="txt_pertainsToLine2">Pertains To (Continued)</label>
                    <input automation_id="txt_pertainsToLine2"
                        class="form-control"
                        type="text"
                        v-model="item.pertainsToLine2"
                    />
                </div>
                <div :class="{ 'col col-4 col-xl-4 form-group form-required': true, 'has-error': v$.item.transactionFundsAmount.$error }">
                    <label for="txt_transactionFundsAmount">Funds Amount</label>
                    <rq-input-number
                        automation_id="txt_transactionFundsAmount"
                        ref="txt_transactionFundsAmount"
                        formatType="money"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="v$.item.transactionFundsAmount.$model"
                        />
                        <rq-validation-feedback>Funds Amount is required</rq-validation-feedback>
                </div>
            </div>
        </rq-section-card>
        <rq-section-card title="Agent Contact Info" collapsible>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <!-- <div :class="{ 'col col-6 col-xl-6 form-group form-required': true, 'has-error': v$.item.agentContact.name.$error }"> -->
                    <label for="drp_agent_contact">Agent Contacts</label>
                    <dx-select-box
                        automation_id="drp_agent_contact"
                        value-expr="id"
                        display-expr="name"
                        :data-source="agentContacts"
                        v-model="agentContactID"
                        @valueChanged="onChangeAgent"
                        :showClearButton="true"
                        :search-enabled="false"
                    />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-4 col-xl-4 form-group form-required': true, 'has-error': v$.item.agentContact.name.$error }">
                    <label for="txt_agent_name">Agent Name</label>
                    <input automation_id="txt_agent_name"
                        class="form-control"
                        type="text"
                        v-model="v$.item.agentContact.name.$model"
                        :disabled="disableAgent"
                    />
                    <rq-validation-feedback>Agent Name is required</rq-validation-feedback>
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Agent Fax Number"
                        labelFor="txt_agent_fax"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_deliverToAgentViaFax"
                                id="chk_deliverToAgentViaFax"
                                v-model="item.deliverToAgentViaFax"
                                :disabled="disableDeliverToAgentViaFax">Deliver via Fax
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <rq-masked-input id="txt_agent_fax" placeholder="Agent Fax Number" v-model="item.agentContact.fax" maskType="phone" isMasked="true" :disabled="disableAgent"></rq-masked-input>
                            <rq-validation-feedback>Agent fax number format is invalid.</rq-validation-feedback>
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Agent Email"
                        labelFor="txt_agent_email"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_deliverToAgentViaEmail"
                                id="chk_deliverToAgentViaEmail"
                                v-model="item.deliverToAgentViaEmail"
                                :disabled="disableDeliverToAgentViaEmail">Deliver via Email
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <input automation_id="txt_agent_email"
                                class="form-control"
                                type="text"
                                v-model="item.agentContact.email"
                                :disabled="disableAgent"
                                />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </rq-section-card>
        <rq-section-card title="Lender Info" headerSize="sm" collapsible>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="drp_lenders">Lenders</label>
                    <dx-select-box
                        automation_id="drp_lenders"
                        value-expr="loanID"
                        display-expr="lender"
                        :data-source="loans"
                        v-model="loanID"
                        :showClearButton="true"
                        :search-enabled="false"
                        @valueChanged="onChangeLoan"
                        />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_loan_number">Loan Number</label>
                    <input automation_id="txt_loan_number"
                        class="form-control"
                        type="text"
                        v-model="item.loanNumber"
                    />
                </div>
            </div>
            <div class="row" v-if="showLenderDetails">
                <div :class="{ 'col col-6 col-xl-6 form-group form-required': true, 'has-error': v$.itemParty.name.$error }">
                    <label for="txt_lender_name">Name</label>
                    <input automation_id="txt_lender_name"
                        class="form-control"
                        type="text"
                        v-model="v$.itemParty.name.$model"
                    />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_lender_name">Name (continued)</label>
                    <input automation_id="txt_lender_name"
                        class="form-control"
                        type="text"
                        v-model="itemParty.nameContinued"
                    />
                </div>
            </div>
            <div class="row" v-if="showLenderDetails">
                <div :class="{ 'col col-6 col-xl-6 form-group form-required': true, 'has-error': v$.itemParty.address.line1.$error }">
                    <label for="txt_lender_address_1">Address 1</label>
                    <input automation_id="txt_lender_address_1"
                        class="form-control"
                        type="text"
                        v-model="v$.itemParty.address.line1.$model"
                    />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_lender_address_2">Address 2</label>
                    <input automation_id="txt_lender_address_2"
                        class="form-control"
                        type="text"
                        v-model="itemParty.address.line2"
                    />
                </div>
            </div>
            <div class="row" v-if="showLenderDetails">
                <div :class="{ 'col col-4 col-xl-4 form-group form-required': true, 'has-error': v$.itemParty.address.city.$error }">
                    <label for="txt_lender_address_city">City</label>
                    <input automation_id="txt_lender_address_city"
                        class="form-control"
                        type="text"
                        v-model="v$.itemParty.address.city.$model"
                    />
                    <rq-validation-feedback>City is required</rq-validation-feedback>
                </div>
                <div :class="{ 'col col-4 col-xl-4 form-group form-required': true, 'has-error': v$.itemParty.address.stateCode.$error }">
                    <label class="form-control-label" for="txt_lender_address_state">State</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'txt_lender_address_state' }"
                        :items="usStatesLookup"
                        value-expr="id"
                        display-expr="id"
                        v-model="v$.itemParty.address.stateCode.$model"
                    />
                    <rq-validation-feedback>State is required</rq-validation-feedback>
                </div>
                <div :class="{ 'col col-4 col-xl-4 form-group form-required': true, 'has-error': v$.itemParty.address.zipCode.$error }">
                    <label for="txt_lender_address_zip">Zip</label>
                    <input automation_id="txt_lender_address_zip"
                        class="form-control"
                        type="text"
                        v-model="v$.itemParty.address.zipCode.$model"
                    />
                    <rq-validation-feedback>Zip is required</rq-validation-feedback>
                </div>
            </div>
            <div class="row" v-if="showLenderDetails">
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_lender_phone">Lender Phone</label>
                    <input automation_id="txt_lender_phone"
                        class="form-control"
                        type="text"
                        v-model="itemParty.telephoneNumber"
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Lender Fax"
                        labelFor="txt_agent_fax"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_deliverToAddresseeViaFax"
                                id="chk_deliverToAddresseeViaFax"
                                v-model="item.deliverToAddresseeViaFax"
                                >Deliver via Fax
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <input automation_id="txt_agent_fax"
                                class="form-control"
                                type="text"
                                v-model="itemParty.faxNumber"
                                />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Lender Email"
                        labelFor="txt_lender_email"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_deliverToAddresseeViaEmail"
                                id="chk_deliverToAddresseeViaEmail"
                                v-model="item.deliverToAddresseeViaEmail"
                                >Deliver via Email
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <input automation_id="txt_lender_email"
                                class="form-control"
                                type="text"
                                v-model="itemParty.emailAddress"
                                />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
            <div class="row" v-if="showLenderDetails">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_lender_contacct">Lender Contact</label>
                    <input automation_id="txt_lender_contacct"
                        class="form-control"
                        type="text"
                        v-model="itemParty.contactName"
                    />
                </div>
            </div>
        </rq-section-card>
        <rq-section-card title="Branch Office Info" collapsible>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <rq-action-form-group
                        label="Branch Office"
                        labelFor="drp_branch"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_includeAllOfTheAgencyBranchOfficesOnThisLetter"
                                id="chk_includeAllOfTheAgencyBranchOfficesOnThisLetter"
                                v-model="item.includeAllOfTheAgencyBranchOfficesOnThisLetter"
                                >Include all Agency Branches
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <dx-select-box
                                automation_id="drp_branch"
                                value-expr="id"
                                display-expr="name"
                                :data-source="lookupData.branchOffices"
                                v-model="item.branchOfficeId"
                                :showClearButton="true"
                                @valueChanged="onChangeBranch"
                            />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_branch_name">Name</label>
                    <input automation_id="txt_branch_name"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.name"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_branch_name">Name (continued)</label>
                    <input automation_id="txt_branch_name"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.nameContinued"
                        readonly
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_branch_address_1">Address 1</label>
                    <input automation_id="txt_branch_address_1"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.addressLine1"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_branch_address_2">Address 2</label>
                    <input automation_id="txt_branch_address_2"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.addressLine2"
                        readonly
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_branch_address_city">City</label>
                    <input automation_id="txt_branch_address_city"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.city"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label class="form-control-label" for="txt_branch_address_state">State</label>
                    <input automation_id="txt_branch_address_state"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.state.stateCode"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_branch_address_zip">Zip</label>
                    <input automation_id="txt_branch_address_zip"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.zipCode"
                        readonly
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_branch_phone">Phone</label>
                    <input automation_id="txt_branch_phone"
                        class="form-control"
                        type="text"
                        :value="selectedBranch.telephoneNumber"
                        readonly
                        disabled
                    />
                </div>
            </div>
        </rq-section-card>
        <rq-section-card title="Closing Attorney Info" collapsible>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="drp_attorney">Closing Attorney</label>
                    <dx-select-box
                        automation_id="drp_attorney"
                        value-expr="id"
                        display-expr="name"
                        :data-source="lookupData.closingAttorneys"
                        v-model="item.closingAttorneyId"
                        @valueChanged="onChangeAttorney"
                        :showClearButton="true"
                        />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_attorney_name">Name</label>
                    <input automation_id="txt_attorney_name"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.name"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_attorney_name">Name (continued)</label>
                    <input automation_id="txt_attorney_name"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.nameContinued"
                        readonly
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_attorney_address_1">Address 1</label>
                    <input automation_id="txt_attorney_address_1"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.addressLine1"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-6 col-xl-6 form-group">
                    <label for="txt_attorney_address_2">Address 2</label>
                    <input automation_id="txt_attorney_address_2"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.addressLine2"
                        readonly
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_attorney_address_city">City</label>
                    <input automation_id="txt_attorney_address_city"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.city"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label class="form-control-label" for="txt_attorney_address_state">State</label>
                    <input automation_id="txt_attorney_address_state"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.state.stateCode"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_attorney_address_zip">Zip</label>
                    <input automation_id="txt_attorney_address_zip"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.zipCode"
                        readonly
                        disabled
                    />
                </div>
            </div>
            <div class="row">
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_attorney_phone">Attorney Phone</label>
                    <input automation_id="txt_attorney_phone"
                        class="form-control"
                        type="text"
                        :value="selectedAttorney.telephoneNumber"
                        readonly
                        disabled
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Attorney Fax Number"
                        labelFor="txt_attorney_fax"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_deliverToClosingAttorneyViaFax"
                                id="chk_deliverToClosingAttorneyViaFax"
                                v-model="item.deliverToClosingAttorneyViaFax"
                                >Deliver via Fax
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <input automation_id="txt_attorney_fax"
                                class="form-control"
                                type="text"
                                :value="selectedAttorney.faxNumber"
                                readonly
                                disabled
                                />
                        </template>
                    </rq-action-form-group>
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <rq-action-form-group
                        label="Attorney Email"
                        labelFor="txt_attorney_email"
                        :show-action="true"
                        >
                        <template #action>
                            <b-form-checkbox
                                automation_id="chk_deliverToClosingAttorneyViaEmail"
                                id="chk_deliverToClosingAttorneyViaEmail"
                                v-model="item.deliverToClosingAttorneyViaEmail"
                                >Deliver via Email
                            </b-form-checkbox>
                        </template>
                        <template #default>
                            <input automation_id="txt_attorney_email"
                                class="form-control"
                                type="text"
                                :value="selectedAttorney.emailAddress"
                                readonly
                                disabled
                                />
                        </template>
                    </rq-action-form-group>
                </div>
            </div>
        </rq-section-card>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { PartyTypes } from "./enums";
    import { CplAddress, CplOrder, CplTransactionParty } from "./models";
    const defaultContact = { name: null, nameContinued: null, contactName: null, addressLine1: null, addressLine2: null, city: null, state:{stateCode: null}, zipCode: null, telephoneNumber: null, faxNumber: null, emailAddress: null};

    export default {
        name: "AddEditCmClosingProtectionLetter",
        props: {
            cplType: { type: Number, required: true, default: 0 },
            existingCpls: { type: Array, required: true, default: () => [] },
            contacts: { type: Array, required: true, default: () => [] },
            lookupData: { type: Object, required: true, default: () => {} },
            cmClosingProtectionLetterID: { type: Number, required: false, default: 0 },
            referenceNumber: { type: String, required: false, default: null },
            cplOrder: { type: Object, required: false, default: () => {} }
        },
        inject: ["dialogId"],
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                agentContactID: null,
                canReceiveCplByFax: true,
                canReceiveCplByEmail: true,
                item: new CplOrder(),
                itemParty: new CplTransactionParty(),
                rolesID: null,
                loanID: null,
                propertyID: 0,
                propertyList: [{ id: 0, description: "..Select Property"}],
                transactionTypes: [{id: "Single", name: "Single Transaction Letter"}, {id: "Cash", name: "Cash Transaction Letter"}],
                letterTypes: [],
                agentContacts: [],
                selectedBranch: defaultContact,
                selectedAttorney: defaultContact,
                errorMessage: "",
            };
        },

        created() {
            this.$emit("disable-ok");
            this.init();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "usStatesLookup"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                user: state => state.authentication.session.user,
                loans: state => state.orders.loans,
                properties: state => state.properties.properties,
            }),
            disableAgent() { return _.parseNumber(this.agentContactID, 0) > 0; },
            disableDeliverToAgentViaEmail() { return !this.canReceiveCplByEmail || _.size(this.item.agentContact.email) == 0; },
            disableDeliverToAgentViaFax() { return !this.canReceiveCplByFax || _.size(this.item.agentContact.fax) == 0; },
            isNew() { return _.isEmpty(this.cplOrder); },
            partyType() { return `${PartyTypes.displayValue(this.cplType)}`; },
            title() { return `${this.partyType} Closing Protection Letter`; },
            showBanner() { return _.size(this.errorMessage) > 0; },
            showLenderDetails() { return _.isEqual(this.cplType, PartyTypes.Lender); },
            tooltipContainer() { return `#${this.dialogId}`; },
        },

        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                    this.errorMessage = newValue ? 'Please correct the highlighted fields.' : "";
                },
                deep: true,
                immediate: true
            },
        },

        validations: () => ({
            item: {
                agentContact: {
                    name: { required }
                },
                property: {
                    line1: { required }
                },
                transactionFundsAmount: { required },
                pertainsToLine1: { required }
            },
            itemParty: {
                name: { required },
                address: {
                    line1: { required },
                    city: { required },
                    stateCode: { required },
                    zipCode: { required }
                }
            }
        }),

        methods: {
            init() {
                const self = this;
                if (self.properties) {
                    self.propertyList = [];
                    _.each(self.properties, p => {
                        self.propertyList.push({ id: p.property.propertyID, name: `${p.property.address1}, ${p.property.city}, ${p.property.state}, ${p.property.zip}`, cplAddress: new CplAddress({ line1: p.property.address1, line2: p.property.address2, city: p.property.city, stateCode: p.property.state, zipCode: p.property.zip})});
                    });
                }
                if (!_.isEmpty(self.lookupData)) {
                    self.agentContacts = [];
                    _.each(self.lookupData.agentContacts, c => {
                        self.agentContacts.push({ id: c.contactId, name: `${c.fullName} - ${c.emailAddress}`});
                    });
                    _.each(self.lookupData.authorizedItems, a => {
                        let pt = _.find(a.addresseeTypes, { partyType: self.partyType});
                        if (pt) {
                            self.item.letterType = pt.letterTypes[0]?.letterTypeName;
                            self.letterTypes = pt.letterTypes;
                        }
                    });
                }
                if (self.isNew) {
                    //add Order
                    self.item.letterTransactionType = "Single";
                    self.propertyID = self.properties[0]?.property?.propertyID || 0;
                    if (_.isEqual(self.propertyID, 0)) {
                        self.errorMessage = "Missing Property Address.";
                    } else {
                        self.setProperty(self.propertyID);
                    }
                    if (_.size(self.loans) == 1) {
                        self.setLender(self.loans[0]);
                    }
                    const buyer1 = _.find(self.contacts, { partyType: PartyTypes.Buyer, sequence: 1 });
                    if (buyer1) {
                        self.item.pertainsToLine1 = buyer1.name;
                    }
                    const buyer2 = _.find(self.contacts, { partyType: PartyTypes.Buyer, sequence: 2 });
                    if (buyer2) {
                        self.item.pertainsToLine2 = buyer2.name;
                    }
                } else {
                    //edit Order
                    const branch = _.find(self.lookupData.branchOffices, { id: _.parseNumber(self.cplOrder.branchOfficeId, 0) });
                    self.setBranch(branch);
                    const agent = _.find(self.lookupData.agentContacts, { fullName: self.cplOrder.agentContact.name });
                    self.setAgent(agent);
                    const attorney = _.find(self.lookupData.closingAttorneys, { id: _.parseNumber(self.cplOrder.closingAttorneyId, 0) });
                    self.setAttorney(attorney);
                    const property = _.find(self.propertyList, i => i.cplAddress.line1 == self.cplOrder.property.line1);
                    self.setProperty(property?.id || 0);
                    const loan = _.find(self.loans, { amount: self.cplOrder.transactionFundsAmount });
                    self.setLender(loan);

                    self.item = _.clone(self.cplOrder);
                    self.itemParty = self.item.transactionParties[0];
                    self.item.branchOfficeId = _.parseNumber(self.item.branchOfficeId, null);
                    self.item.closingAttorneyId = _.parseNumber(self.item.closingAttorneyId, null);
                }
            },

            onChangeAgent(e) {
                const self = this;
                const agent = _.find(self.lookupData.agentContacts, { contactId: e.value });
                self.setAgent(agent);
            },

            onChangeAttorney(e) {
                const self = this;
                const attorney = _.find(self.lookupData.closingAttorneys, { id: e.value });
                self.setAttorney(attorney);
            },

            onChangeBranch(e) {
                const self = this;
                const branch = _.find(self.lookupData.branchOffices, { id: e.value });
                self.setBranch(branch);
            },

            onChangeLoan(e) {
                const self = this;
                const loan = _.find(self.loans, { loanID: e.value});
                self.setLender(loan);
            },

            onChangeProperty(e) {
                const self = this;
                const property = _.find(self.propertyList, { id: e.value});
                self.setProperty(property.id);
            },

            save(){
                const self = this;
                self.errorMessage = "";
                self.setCplOrder();
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields and correct errors.'}); }
                let apiPromise = self.$api.CmClosingProtectionLettersApi.createClosingProtectionLetter(self.orderId, self.rolesID, self.item);
                return self.$rqBusy.wait(apiPromise);
            },

            setAgent(agent) {
                const self = this;
                if (agent) {
                    self.agentContactID = agent.contactId;
                    self.item.agentContact = { name: agent.fullName, fax: agent.faxNumber, email: agent.emailAddress, phone: ""};
                    self.canReceiveCplByFax = agent.canReceiveCplByFax;
                    self.canReceiveCplByEmail = agent.canReceiveCplByEmail;
                } else {
                    self.agentContactID = null;
                    self.item.agentContact = { name: null, fax: null, email: null, phone: ""};
                    self.canReceiveCplByFax = true;
                    self.canReceiveCplByEmail = true;
                }
            },

            setAttorney(attorney) {
                const self = this;
                if (attorney) {
                    self.selectedAttorney = attorney;
                } else {
                    self.selectedAttorney = defaultContact;
                }
            },

            setBranch(branch) {
                const self = this;
                if (branch) {
                    self.selectedBranch = branch;
                } else {
                    self.selectedBranch = defaultContact;
                }
            },

            setCplOrder() {
                const self = this;
                self.item.branchOfficeId = `${self.item.branchOfficeId}`;
                self.item.closingAttorneyId = `${self.item.closingAttorneyId}`;
                if (_.isEqual(self.cplType, PartyTypes.Borrower) || _.isEqual(this.cplType, PartyTypes.Buyer)) {
                    const buyer = _.find(self.contacts, { partyType: self.cplType, sequence: 1 });
                    self.rolesID = buyer?.rolesID || 0;
                    let address = new CplAddress({ line1: buyer?.address1, line2: buyer?.address2, city: buyer?.addressCity, stateCode: buyer?.addressState, zipCode: buyer?.addressZip });
                    let party = new CplTransactionParty({ name: buyer?.name, contactName: null, emailAddress: buyer?.emailAddress, telephoneNumber: buyer?.telephoneNumber, faxNumber: buyer?.faxNumber, address, partyType: self.cplType});
                    self.itemParty = party;
                }
                if (_.isEqual(self.cplType, PartyTypes.Seller)) {
                    const seller = _.find(self.contacts, { partyType: self.cplType, sequence: 1 });
                    self.rolesID = seller?.rolesID || 0;
                    let address = new CplAddress({ line1: seller?.address1, line2: seller?.address2, city: seller?.addressCity, stateCode: seller?.addressState, zipCode: seller?.addressZip });
                    let party = new CplTransactionParty({ name: seller.name, contactName: null, emailAddress: seller?.emailAddress, telephoneNumber: seller?.telephoneNumber, faxNumber: seller?.faxNumber, address, partyType: self.cplType});
                    self.itemParty = party;
                }
                self.item.transactionParties = [];
                self.item.transactionParties.push(self.itemParty);
            },

            setLender(loan){
                const self = this;
                if (self.showLenderDetails && self.isNew) {
                    let lender = _.find(self.contacts, { sequence: loan.loanOrder, partyType: PartyTypes.Lender });
                    self.rolesID = lender?.rolesID || 0;
                    let address = new CplAddress({ line1: lender?.address1, line2: lender?.address2, city: lender?.addressCity, stateCode: lender?.addressState, zipCode: lender?.addressZip });
                    let lenderParty = new CplTransactionParty({ name: lender.name, contactName: lender?.contactName, emailAddress: lender?.eMailAddress, telephoneNumber: lender?.phone, faxNumber: lender?.fax, address, partyType: self.cplType});
                    self.itemParty = lenderParty;
                }
                self.item.loanNumber = loan.number;
                self.item.transactionFundsAmount = loan.amount;
                self.loanID = loan.loanID;
            },

            setProperty(propertyID){
                const self = this;
                self.propertyID = propertyID;
                let property = _.find(self.propertyList, { id: propertyID });
                self.item.property = property?.cplAddress || new CplAddress();
            },

            update(){
                const self = this;
                self.errorMessage = "";
                self.setCplOrder();
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields and correct errors.'}); }
                let apiPromise = self.$api.CmClosingProtectionLettersApi.updateClosingProtectionLetter(self.orderId, self.cmClosingProtectionLetterID, self.referenceNumber, self.item);
                return self.$rqBusy.wait(apiPromise);
            },
        }
    }
</script>